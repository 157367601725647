import { apiRoute, authHeader, handleResponse } from '../helpers'

export const staffReportService = {
  email,
  generatePdf,
  get,
  save,
  sendToLocal,
  sendToRemote,
  getIlo,
  saveIlo
}

function get (identifier) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/' + identifier, requestOptions).then(handleResponse)
}

function getIlo (identifier) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/ilo_get/' + identifier, requestOptions).then(handleResponse)
}

function saveIlo (report) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(report)
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/ilo_save', requestOptions).then(handleResponse)
}
function save (report) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(report)
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/' + report.submission, requestOptions).then(handleResponse)
}

function email (id, email) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify({ address: email })
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/email/' + id, requestOptions).then(handleResponse)
}

function generatePdf (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/generate/' + id, requestOptions).then(handleResponse)
}

function sendToLocal (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/local/' + id, requestOptions).then(handleResponse)
}

function sendToRemote (id) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: null
  }

  return fetch(apiRoute() + '/api/v1/staff/reports/remote/' + id, requestOptions).then(handleResponse)
}
