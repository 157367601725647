import { staffReportService } from '../services'

const state = {
  info: {},
  status: {
    loaded: false,
    loading: false,
    notFound: false,
    saving: false,
    saved: false
  },
  ilo: {
    report: null,
    reportLoading: false
  }
}

const actions = {
  get ({ dispatch, commit }, identifier) {
    commit('getReport')

    staffReportService.get(identifier)
      .then(
        report => {
          if (!report) {
            commit('getReportNotFound')
          } else {
            commit('getReportSuccess', report)
          }

          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('getReportFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  getIlo ({ dispatch, commit }, identifier) {
    commit('getReportILO')

    staffReportService.getIlo(identifier)
      .then(
        report => {
          if (!report) {
            commit('getReportILONotFound')
          } else {
            commit('getReportILOSuccess', report)
          }

          dispatch('alert/clear', null, { root: true })
        }
      )
  },
  saveIlo ({ dispatch, commit }, report) {
    commit('saveReportILO', report)
    // const _submission = report.submission

    staffReportService.saveIlo(report)
      .then(
        report => {
          commit('saveReportILOSuccess')
          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('saveReportILOFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  save ({ dispatch, commit }, report) {
    commit('saveReport', report)
    const _submission = report.submission

    staffReportService.save(report)
      .then(
        report => {
          commit('saveReportSuccess', report)
          dispatch('staffSubmission/get', _submission, { root: true })
          dispatch('alert/clear', null, { root: true })
        },
        error => {
          commit('saveReportFailure')
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  clear ({ commit }) {
    commit('clearReport')
  }
}

const mutations = {
  getReport (state) {
    state.info = null
    state.status = {
      loaded: false,
      loading: true,
      notFound: false,
      saving: false,
      saved: false
    }
  },
  getReportILO (state) {
    state.ilo.reportLoading = true
  },
  saveReportILO (state, report) {
    state.ilo.report = report
    state.ilo.reportLoading = true
  },
  getReportILOSuccess (state, report) {
    state.ilo.report = report.data
    state.ilo.reportLoading = false
  },
  getReportILONotFound (state) {
    state.ilo.reportLoading = false
  },
  saveReportILOSuccess (state) {
    state.ilo.reportLoading = false
  },
  saveReportILOFailure (state) {
    state.ilo.reportLoading = false
  },
  getReportSuccess (state, report) {
    state.info = report
    state.status = {
      loaded: true,
      loading: false,
      notFound: false,
      saving: false,
      saved: false
    }
  },
  getReportNotFound (state) {
    state.info = null
    state.status = {
      loaded: true,
      loading: false,
      notFound: true,
      saving: false,
      saved: false
    }
  },
  getReportFailure (state) {
    state.info = null
    state.status = {
      loaded: true,
      loading: false,
      notFound: false,
      saving: false,
      saved: false
    }
  },
  saveReport (state, report) {
    state.info = report
    state.status = {
      loaded: false,
      loading: false,
      notFound: false,
      saving: true,
      saved: false
    }
  },
  saveReportSuccess (state, report) {
    state.info = report
    state.status = {
      loaded: false,
      loading: false,
      notFound: false,
      saving: false,
      saved: true
    }
  },
  saveReportFailure (state) {
    state.info = null
    state.status = {
      loaded: false,
      loading: false,
      notFound: false,
      saving: false,
      saved: true
    }
  },
  clearReport (state) {
    state.info = null
    state.status = {
      loaded: false,
      loading: false,
      notFound: false,
      saving: false,
      saved: false
    }
  }
}

export const staffReport = {
  namespaced: true,
  state,
  actions,
  mutations
}
